<template>

  <div
    ref="imagecontent"
    class="image-upload-container"
    @ondrop="drop()"
  >
    <input
      ref="imageUploadField"
      class="inputFile"
      type="file"
      accept="image/*"
      @dragover="drag()"
      @drop="drop()"
      @change="upload($event)"
    >
    <div
      v-if="!imageUrl && !isLoading"
      class="img-text"
    >
      {{ text }}
    </div>
    <img
      v-show="imageUrl && isLoaded"
      :src="imageUrl"
      alt="app icon"
      @load="loaded"
    >
    <b-spinner
      v-if="isLoading"
      class="spinner"
      variant="primary"
    />
  </div>
</template>
<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
  emits: ['image-uploaded'],
  props: {
    text: {
      type: String,
      default: 'Drop file here or click to upload image',
    },
    imageUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      file: null,
      isLoading: false,
      isLoaded: false,
    }
  },
  methods: {
    select() {
      this.$refs.imageUploadField.click()
    },
    upload(event) {
      const file = event.target.files[0]
      if (file && file.type.split('/')[0] === 'image') {
        this.isLoaded = false
        this.isLoading = true
        this.$store
          .dispatch('common/uploadNewImage', file)
          .then(url => {
            this.imageUrl = url
            this.$emit('image-uploaded', this.imageUrl)
            this.isLoaded = false
          })
      } else {
        this.$store.dispatch('toaster/setMessage', {
          title: 'Please Select an Image',
          icon: 'UploadIcon',
          variant: 'danger',
        })
      }
    },
    drop() {

    },
    loaded() {
      this.isLoading = false
      this.isLoaded = true
    },
  },
  setup() {
    setTimeout(() => {
      this.$refs.imagecontent
        .addEventListener('click', event => {
          console.log(event)
        // do something
        })
    }, 0)
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/core.scss';
.image-upload-container{
    border: 1px solid #e9e8e8be;
    height: 100%;
    min-height: 250px;
     position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
   justify-content: center;
}

img{
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 36px;
  max-width: 100%;
  height: 100%;
  max-height: 400px;
}
.img-text{
    width: 100%;
    text-align: center;
   justify-content: center;
}
.image-upload-container:hover{
    background-color: #f7f7f7e3;
}
.spinner{
 position: sticky;
}
.dark-layout .image-upload-container:hover {
    background-color: $theme-dark-input-bg;
  }
.inputFile{
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;
}
</style>
